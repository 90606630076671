<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'campaignIndex' }">{{ $t('seller.campaign.title') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.signUpCampaign') }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-filters">
				<el-form inline size="medium">
					<el-form-item :label="$t('seller.screen.campaignName') + ':'">
						<el-input v-model="pageQuery.name" :placeholder="$t('seller.placeholder.default')" clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.campaignTag') + ':'">
						<el-select v-model="pageQuery.activity_label_id" clearable>
							<el-option v-for="item in tagsOptions" :key="item.activity_label_id" :label="item.label_name" :value="item.activity_label_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.campaignStatus') + ':'">
						<el-select v-model="pageQuery.activity_status" clearable>
							<el-option v-for="item in $t('seller.options.campaignType')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.signUpPerfectStatus') + ':'">
						<el-select v-model="pageQuery.per_status" class="select-perfect" clearable>
							<el-option v-for="item in $t('seller.options.perfectStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.signRecordId') + ':'">
						<el-input v-model="pageQuery.activity_store_join_id" :placeholder="$t('seller.placeholder.default')" class="input-records"
							clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.campaign.activityTime') + ':'">
						<el-date-picker v-model="campaignTime" type="datetimerange" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')"
							:end-placeholder="$t('seller.placeholder.endDate')">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('seller.campaign.signUpTime') + ':'">
						<el-date-picker v-model="applicationTime" type="datetimerange" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')"
							:end-placeholder="$t('seller.placeholder.endDate')">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearchFilter">{{ $t('seller.actions.search') }}</el-button>
						<el-button type="seller__clear" icon="el-icon-brush" @click="handleSearchReset">{{ $t('seller.actions.clearQuery') }}</el-button>
					</el-form-item>
				</el-form>
				<div class="filters-sort">
					<el-radio-group v-model="pageQuery.order_field" size="medium" @change="handleChangeSort">
						<el-radio :label="1" border><i class="el-icon-sort-down"></i> {{ $t('seller.screen.signUpTimeSort') }}</el-radio>
						<el-radio :label="2" border><i class="el-icon-sort-down"></i> {{ $t('seller.screen.campaignTimeSort') }}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<el-table v-loading="listLoading" :data="pageList" border class="seller-table">
				<el-table-column :label="$t('seller.tableHead.campaignInfo')" min-width="300px">
					<template slot-scope="scope">
						<div class="campaign-item">
							<div class="tags">
								<el-tag type="info" size="mini">{{ scope.row.label_name }}</el-tag>
							</div>
							<div class="name"><a href="javascript:void(0);">{{ scope.row.name }}</a></div>
							<div class="text">
								<span>{{ $t('seller.campaign.shopSignUp') }}</span>
								<span>{{scope.row.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}')}}-{{scope.row.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}')}}</span>
							</div>
							<div class="text">
								<span>{{ $t('seller.campaign.itemSignUp') }}</span>
								<span>{{scope.row.g_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}')}}-{{scope.row.g_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}')}}</span>
							</div>
							<div class="text">
								<span>{{ $t('seller.campaign.warmUpTime') }}</span>
								<span>{{scope.row.warm_up_start_time | parseTime('{y}.{m}.{d} {h}:{i}')}}-{{scope.row.warm_up_end_time | parseTime('{y}.{m}.{d} {h}:{i}')}}</span>
							</div>
							<div class="text">
								<span>{{ $t('seller.campaign.salesTime') }}</span>
								<span>{{scope.row.sales_start_time | parseTime('{y}.{m}.{d} {h}:{i}')}}-{{scope.row.sales_end_time | parseTime('{y}.{m}.{d} {h}:{i}')}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.goodsAuditInfo')">
					<template slot-scope="scope">
						<div class="text-item" v-if="scope.row.goods_draft_num > 0">
							<span>{{ $t('seller.campaign.draft') }}</span><span>{{ scope.row.goods_draft_num }}</span>
						</div>
						<div class="text-item" v-if="scope.row.goods_fail_num > 0">
							<span>{{ $t('seller.campaign.unapproved') }}</span><span>{{ scope.row.goods_fail_num }}</span>
						</div>
						<div class="text-item" v-if="scope.row.goods_adopt_num > 0">
							<span>{{ $t('seller.campaign.approved') }}</span><span>{{ scope.row.goods_adopt_num }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.screen.campaignStatus')">
					<template slot-scope="scope">
						{{ scope.row.a_state | typeFilter($t('seller.filters.campaignStatusText'))}}
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.handle')">
					<template slot-scope="scope">
						<div>
							<el-button type="text" @click="$router.push({ name: 'campaignSignDetail', params: { id: scope.row.activity_id }})">{{ $t('seller.actions.viewDetails') }}</el-button>
						</div>
						<div>
							<el-button type="text" @click="$router.push({ name: 'campaignDetail', params: { id: scope.row.activity_id }})">{{ $t('seller.actions.viewCampaignDesc') }}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
				@pagination="getList"></pagination>
		</el-card>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCampaignRecords, fetchCampaignTags } from '@/api/seller/market'

const queryDefault = {
	activity_label_id: '',
	activity_status: '',
	activity_store_join_id: '',
	name: '',
	per_status: '',
	order_field: 1,
	order_type: 'desc'
}

export default {
	components: { Pagination },
	data() {
		return {
			campaignTime: '',
			applicationTime: '',
			pageQuery: Object.assign({}, queryDefault),
			tagsOptions: [],
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0
		}
	},
	created() {
		this.getData()
		this.getList()
	},
	methods: {
		async getData() {
			const tags = await fetchCampaignTags()
			this.tagsOptions = tags.data.list
		},
		getList() {
			this.listLoading = true
			const params = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			if (this.campaignTime) {
				params.ac_time_from = parseInt(this.campaignTime[0] / 1000)
				params.ac_time_to = parseInt(this.campaignTime[1] / 1000)
			}
			if (this.applicationTime) {
				params.enroll_time_from = parseInt(this.applicationTime[0] / 1000)
				params.enroll_time_to = parseInt(this.applicationTime[1] / 1000)
			}
			fetchCampaignRecords(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearchFilter() {
			this.pageCurr = 1
			this.getList()
		},
		handleChangeSort(val) {
			this.pageCurr = 1
			this.getList()
		},
		handleSearchReset() {
			this.pageQuery = Object.assign({}, queryDefault)
			this.campaignTime = ''
			this.applicationTime = ''
			this.pageCurr = 1
			this.getList()
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-filters {
		.filters-sort {
			margin-bottom: 18px;
			::v-deep .el-radio-group {
				.el-radio {
					margin-right: 12px;
					border-radius: 18px;
					.el-radio__inner {
						width: 0;
						height: 0;
						border: none;
					}
				}
			}
		}

	}
	.campaign-item {
		.tags {
			margin: 2px 0;

			.el-tag {
				color: #666666;
				background-color: #ebecf0;
				border-color: #ebecf0;
			}
		}

		.name {
			font-size: 14px;
			line-height: 1.5;
			margin-bottom: 6px;

			a {
				color: $--seller-primary;
			}
		}

		.text {
			line-height: 1.28571em;
			margin-bottom: 6px;
			color: #111111;
			font-size: 12px;

			span {
				margin-right: 10px;
			}
		}
	}

	.text-item {
		color: #999999;

		span {
			margin-right: 6px;
		}
	}
}
</style>